import { useEffect } from "react";


const InstallPWA = ()=>{
  let deferredPrompt:any;
  
  const installButton = async ()=>{
    try {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        deferredPrompt = null;
      }
    } catch (e) {
      console.log('navegador não suportado');
    }
  }

  const install = ()=>{
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log('azdasdasd')
      deferredPrompt = e;
      if (e) {
        const button = document.getElementById('installPWAButton')
        if (button) {
          button.style.display = "block"
        }
      }
    });
  }

  useEffect(()=>{
    install()
  }, [])

  return (
    <button className="home-button1 button" style={{display: 'none'}} id="installPWAButton" onClick={installButton}>Instalar PWA</button>
  )
}

export default InstallPWA