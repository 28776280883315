import React from 'react'

import InstallPWA from './installPwa';
import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Cartão CODERTROOP</title>
        <meta property="og:title" content="Cartão CODERTROOP" />
      </Helmet>
      <div className="home-container1">
        <div className="home-container2">
          <img
            alt="image"
            src="/external/logo-200h.png"
            className="home-image"
          />
          <img
            alt="image"
            src="/external/codertroop-logo-200h.png"
            className="home-image1"
          />
        </div>
        <div className="home-container3">
          <div className="home-container4">
            <a className='home-image2' href='https://www.linkedin.com/company/codertroop/about/?viewAsMember=true'>
              <img
                src="/external/linkerdin.svg"
                alt="image"
                className="home-image2"
              />
            </a>

            <a className="home-image3" href='https://www.instagram.com/codertroop/'>
              <img
                src="/external/instagran.svg"
                alt="image"
                className="home-image3"
              />
            </a>

            <a className="home-image4" href='mailto:contact@codertroop.io'>
              <img
                src="/external/email.svg"
                alt="image"
                className="home-image4"
                />
            </a>

            <a className="home-image5" href='https://wa.me/558431902876'>
              <img
                src="/external/telefone.svg"
                alt="image"
                className="home-image5"
              />
            </a>
          </div>
        </div>
        <div className="home-container5">
          <span className="home-text">
            Consultoria, desenvolvimento de software e soluções para a
            blockchain e Web3
          </span>
            <a href="https://wa.me/5584981887022">
          <button type="button" className="home-button button">
              Fale conosco
          </button>
            </a>
        </div>

        <InstallPWA />

        {/* <button type="button" className="home-button1 button">
          <span>
            <span className="home-text2">Salvar</span>
            <br></br>
          </span>
        </button> */}
      </div>
      {/* <img
        src="62c1b001-37f5-4965-942b-4b1cc3492208"
        alt="image"
        className="home-image6"
      /> */}
    </div>
  )
}

export default Home
